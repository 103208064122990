import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { MqttTopicVariable, MqttTopicVariableValue } from '../../types';

@Component({})
export default class MqttTopicVariableComponent extends Vue {

    @Prop() topicVariable!: MqttTopicVariable;
    @Prop() isJsonVariable!: boolean;

    @Emit()
    deleted() {
    }

    addTopicVariableValue() {
        this.topicVariable.values.push(new DummyMqttTopicVariableValue());
    }

    deleteTopicVariableValue(valueIdx: number) {
        if (valueIdx >= 0 && valueIdx < this.topicVariable.values.length) {
            this.topicVariable.values.splice(valueIdx, 1);
        }
    }
}

class DummyMqttTopicVariableValue implements MqttTopicVariableValue {
    text = "";
    value = 0;
}
