import { Vue, Component, Prop } from 'vue-property-decorator';
import { StateMachineConfigModel, TriggerConfigModel, TriggerConditionConfigModel, TriggerActionConfigModel, MessageModel } from '../../types';
import GuardianMessages from '../guardian-messages/guardian-messages.vue';
import TextInput from './text-input.vue';
import TriggerConditionConfig from './trigger-condition-config.vue';
import TriggerActionConfig from './trigger-action-config.vue';

class TriggerConditionConfigModelInstance implements TriggerConditionConfigModel {
    kind: string = "None";
    operandLeft: string = "";
    operator: string = "Equal";
    operandRight: string = "";
    messages: MessageModel[] = [];
}

class TriggerActionModelInstance implements TriggerActionConfigModel {
    kind: string = "None";
    values: { [name: string]: string } = {};
    messages: MessageModel[] = [];
}

@Component({
    components: {
        TextInput,
        TriggerConditionConfig,
        TriggerActionConfig,
        GuardianMessages
    }
})
export default class TriggerConfigComponent extends Vue {
    @Prop() trigger!: TriggerConfigModel;
    @Prop() stateMachine!: StateMachineConfigModel;

    isShowingDetails: boolean = false;

    updateTriggerName(newName: string) {
        this.trigger.name = newName;
    }

    toggleDetails() {
        this.isShowingDetails = !this.isShowingDetails;
    }

    addCondition() {
        this.trigger.conditions.push(new TriggerConditionConfigModelInstance());
    }

    deleteCondition(idx: number) {
        if (idx >= 0 && idx < this.trigger.conditions.length) {
            this.trigger.conditions.splice(idx, 1);
        }
    }

    addAction() {
        this.trigger.actions.push(new TriggerActionModelInstance());
    }

    deleteAction(idx: number) {
        if (idx >= 0 && idx < this.trigger.actions.length) {
            this.trigger.actions.splice(idx, 1);
        }
    }
}
