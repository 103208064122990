import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { GuardianModel, VariableAgeState, VariableModel, VariableValueModel } from '../../types';
import GuardianGroupItem from './guardian-group-item.vue';
import ModalYesNo from '../modal-yesno/modal-yesno.vue';

@Component({
    components: {
        GuardianGroupItem,
        ModalYesNo
    }
})
export default class GuardianGroupComponent extends Vue {

    @Prop() title!: string;
    @Prop() guardians!: GuardianModel[];
    @Prop() selectedGuardian!: GuardianModel | null;

    isShowingDetails: boolean = true;

    @Emit()
    variableChanged(guardian: GuardianModel, variable: VariableModel, variableValue: VariableValueModel) {
    }

    @Emit()
    variableValueChanged(guardian: GuardianModel, variable: VariableModel, value: string) {
    }

    @Emit()
    guardianDeleted(guardian: GuardianModel) {
    }

    @Emit()
    guardianSelected(guardian: GuardianModel | null) {
    }

    localGuardians: GuardianModel[] = [];
    showModalGuardianDelete: boolean = false;

    mounted() {
        const showDetailsString = localStorage.getItem(`groupDetails_${this.title}`);
        if (showDetailsString) {
            this.isShowingDetails = showDetailsString === "true";
        }

        this.onGuardiansChanged(this.guardians);
    }

    @Watch("guardians")
    onGuardiansChanged(guardians: GuardianModel[]) {
        this.localGuardians = guardians;
    }

    @Watch("isShowingDetails")
    onIsShowingDetailsChanged() {
        localStorage.setItem(`groupDetails_${this.title}`, this.isShowingDetails.toString());
    }

    isSelectedGuardian(guardian: GuardianModel): boolean {
        return this.selectedGuardian ? this.selectedGuardian.id === guardian.id : false;
    }

    deleteGuardian(answerIsYes: boolean): void {
        this.showModalGuardianDelete = false;
        if (answerIsYes) {
            if (this.selectedGuardian != null) {
                let guardianIdx: number | null = null;
                for (let i = 0; i < this.guardians.length; i++) {
                    if (this.guardians[i].id === this.selectedGuardian.id) {
                        guardianIdx = i;
                        break;
                    }
                }

                if (guardianIdx !== null) {
                    this.guardianDeleted(this.selectedGuardian);
                    this.guardians.splice(guardianIdx, 1);

                    if (guardianIdx < this.guardians.length) {
                        this.guardianSelected(this.guardians[guardianIdx]);
                    } else if (this.guardians.length > 0) {
                        this.guardianSelected(this.guardians[this.guardians.length - 1]);
                    } else {
                        this.guardianSelected(null);
                    }
                }
            }
        }
    }

    hasVariableValue(variable: VariableModel): boolean {
        return variable.ageState !== VariableAgeState.Unknown;
    }

    toggleDetails(): void {
        this.isShowingDetails = !this.isShowingDetails;
    }
}
