import { Vue, Component, Inject } from 'vue-property-decorator';
import { StateMachineStatus } from '../../types';
import Api from '../../api';
import StateMachineStatusComponent from './state-machine-status.vue';
import ModalYesNo from '../modal-yesno/modal-yesno.vue';
import ModelStore from '../../model-store';

@Component({
    components: {
        StateMachineStatusComponent,
        ModalYesNo
    }
})
export default class StateMachineManagerComponent extends Vue {
    selectedStateMachine: StateMachineStatus | null = null;
    showModalStateMachineDelete: boolean = false;

    @Inject() readonly api!: Api;
    @Inject() readonly model!: ModelStore;

    addStateMachine(): void {
        this.api.call('api/StateMachine/Add');
    }

    copyStateMachine(): void {
        if (this.selectedStateMachine !== null) {
            this.api.call(`api/StateMachine/Copy/${this.selectedStateMachine.id}`);
        }
    }

    deleteStateMachine(answerIsYes: boolean) {
        this.showModalStateMachineDelete = false;
        if (answerIsYes) {
            if (this.selectedStateMachine !== null) {
                this.api.call(`api/StateMachine/Delete/${this.selectedStateMachine.id}`);
                this.selectedStateMachine = null;
            }
        }
    }

    isSelectedStateMachine(stateMachine: StateMachineStatus): boolean {
        return this.selectedStateMachine !== null && stateMachine.id === this.selectedStateMachine.id;
    }

    selectStateMachine(stateMachine: StateMachineStatus) {
        this.selectedStateMachine = this.isSelectedStateMachine(stateMachine) ? null : stateMachine;
    }
}
