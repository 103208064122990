import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { StateMachineVariableConfig, GuardianModel, StateMachineStatus, StateMachineValueType, StateMachineVariableType } from '../../types';
import { VariableValueModelInstance } from './variable-value-model-instance';

@Component({})
export default class StateMachineVariableComponent extends Vue {

    @Prop() guardians!: GuardianModel[];
    @Prop() variableConfig!: StateMachineVariableConfig;
    @Prop() stateMachines!: StateMachineStatus[];

    selectedType: string = StateMachineVariableType.Constant;
    selectedGuardian: GuardianModel = { id: -1, name: "", group: "", title: "", communicatorName: "", state: "", health: "", variables: [] };
    selectedGuardianId: number = -1;
    selectedVariableName: string = "";

    selectedStateMachineId: number = -1;

    mounted() {
        this.handleUpdated();
    }

    updated() {
        this.handleUpdated();
    }

    handleUpdated() {
        this.selectedType = this.variableConfig.type;
        this.selectedGuardianId = this.variableConfig.guardianId;
        this.selectedVariableName = this.variableConfig.variableName;
        this.selectedStateMachineId = this.variableConfig.stateMachineId;
    }

    @Watch("selectedType")
    onSelectedType(newType: string) {
        this.variableConfig.type = newType;
        switch (newType) {
            case StateMachineVariableType.Constant:
                this.variableConfig.guardianId = -1;
                this.variableConfig.variableName = "";
                this.variableConfig.stateMachineId = -1;
                this.selectedGuardianId = -1;
                this.selectedVariableName = "";
                this.selectedStateMachineId = -1;
                break;
            case StateMachineVariableType.GuardianVariable:
                this.variableConfig.constant = "";
                this.variableConfig.stateMachineId = -1;
                this.selectedStateMachineId = -1;
                break;
            case StateMachineVariableType.StateMachineState:
                this.variableConfig.guardianId = -1;
                this.variableConfig.variableName = "";
                this.variableConfig.constant = "";
                this.selectedGuardianId = -1;
                this.selectedVariableName = "";
                this.variableConfig.values = [];
                break;
        }
    }

    @Watch("guardians")
    onGuardiansChanges() {
        this.onSelectedGuardianIdChanged(this.selectedGuardianId);
    }

    @Watch("selectedGuardianId")
    onSelectedGuardianIdChanged(newId: number) {
        const guardian = this.guardians.filter(g => g.id === newId)[0];
        if (guardian) {
            this.variableConfig.guardianId = guardian.id;
            this.selectedGuardian = guardian;
        }
    }

    @Watch("selectedVariableName")
    onSelectedVariableNameChanged(newName: string) {
        const selectedVariable = this.selectedGuardian.variables.filter(v => v.name === newName)[0];
        if (selectedVariable) {
            this.variableConfig.variableName = selectedVariable.name;
            this.variableConfig.values = selectedVariable.values ?? [];
            this.variableConfig.valueType = this.variableConfig.values.length > 0 ? StateMachineValueType.Enum : StateMachineValueType.Number;
        }
    }

    @Watch("selectedStateMachineId")
    onSelectedStateMachineIdChanged(newId: number) {
        const selectedStateMachine = this.stateMachines.filter(sm => sm.id === newId)[0];
        if (selectedStateMachine) {
            this.variableConfig.stateMachineId = selectedStateMachine.id;
            this.variableConfig.values = selectedStateMachine.stateNames.map(sn => new VariableValueModelInstance(sn));
            this.variableConfig.valueType = StateMachineValueType.Enum;
        }
    }
}
