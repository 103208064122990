import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { GuardianModel, VariableKind, VariableAgeState, VariableModel, UiVariableModel, BooleanVariableModel, VariableValueModel, EnumVariableModel, ColorVariableModel } from '../../types';
import AgeState from './age-state.vue';

@Component({
    components: {
        AgeState
    }
})
export default class GuardianGroupItemComponent extends Vue {

    @Prop() isSelected!: boolean;
    @Prop() guardian!: GuardianModel;

    @Emit()
    selected() {
    }

    @Emit()
    deleted() {
    }

    @Emit()
    variableChanged(guardian: GuardianModel, variable: VariableModel, variableValue: VariableValueModel) {
    }

    @Emit()
    variableValueChanged(guardian: GuardianModel, variable: VariableModel, value: string) {
    }

    state = "";

    valueVariables: UiVariableModel[] = [];
    booleanVariables: BooleanVariableModel[] = [];
    enumVariables: EnumVariableModel[] = [];
    upDownVariables: UiVariableModel[] = [];
    colorVariables: ColorVariableModel[] = [];

    mounted() {
        this.onGuardianChanged(this.guardian);
    }

    @Watch("guardian")
    onGuardianChanged(newGuardian: GuardianModel) {
        this.state = newGuardian.state;

        this.valueVariables = [];
        this.booleanVariables = [];
        this.enumVariables = [];
        this.upDownVariables = [];
        this.colorVariables = [];
        for (const kindVariable of this.guardian.variables) {
            switch (kindVariable.kind) {
                case VariableKind.Relay:
                case VariableKind.Switch:
                    this.booleanVariables.push(new BooleanVariableModel(kindVariable, this.changeVariable));
                    break;
                case VariableKind.MoveUpDown:
                case VariableKind.StepUpDown:
                case VariableKind.ShutterState:
                    this.upDownVariables.push(new UiVariableModel(kindVariable, this.changeVariable));
                    break;
                case VariableKind.Color:
                    this.colorVariables.push(new ColorVariableModel(kindVariable, this.changeVariableValue));
                    break;
                default:
                    if (!kindVariable.values || kindVariable.values.length === 0) {
                        this.valueVariables.push(new UiVariableModel(kindVariable, this.changeVariable));
                    } else {
                        this.enumVariables.push(new EnumVariableModel(kindVariable, this.changeVariable));
                    }
                    break;
            }
        }
    }

    hasVariableValue(variable: UiVariableModel): boolean {
        return variable.ageState !== VariableAgeState.Unknown;
    }

    isVariableStale(variable: UiVariableModel): boolean {
        return variable.ageState === VariableAgeState.Stale;
    }

    getShutterStyle(variable: UiVariableModel): string {
        if (typeof variable.state === "number") {
            return `width: ${(variable.state * 100).toFixed(0)}%`;
        } else {
            return "width: 0%";
        }
    }

    toPercent(variable: UiVariableModel): string {
        if (typeof variable.state === "number") {
            return `${variable.state * 100}%`;
        } else {
            return "?";
        }
    }

    private changeVariable(variable: VariableModel, variableValue: VariableValueModel) {
        console.log("changeVariable", variable.name, variableValue.title)
        this.variableChanged(this.guardian, variable, variableValue);
    }

    private changeVariableValue(variable: VariableModel, value: string) {
        console.log("changeVariableValue", variable.name, value)
        this.variableValueChanged(this.guardian, variable, value);
    }
}
