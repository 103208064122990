import { Vue, Component, Prop } from 'vue-property-decorator';
import { StateMachineConfigModel, TriggerConfigModel, TriggerConditionConfigModel, TriggerActionConfigModel, MessageModel, StateMachineStateConfigModel } from '../../types';
import TextInput from './text-input.vue';
import TriggerConfig from './trigger-config.vue';
import TriggerActionConfig from './trigger-action-config.vue';
import GuardianMessages from '../guardian-messages/guardian-messages.vue';

class TriggerConfigModelInstance implements TriggerConfigModel {
    name = "";
    conditions: TriggerConditionConfigModel[] = [];
    actions: TriggerActionConfigModel[] = [];
    messages: MessageModel[] = [];

    constructor(name: string) {
        this.name = name;
    }
}

class TriggerActionModelInstance implements TriggerActionConfigModel {
    id = -1;
    kind = "None";
    values: { [name: string]: string } = {};
    messages: MessageModel[] = [];
}

@Component({
    components: {
        TextInput,
        TriggerConfig,
        TriggerActionConfig,
        GuardianMessages
    }
})
export default class StateConfigComponent extends Vue {
    @Prop() stateMachine!: StateMachineConfigModel;
    @Prop() state!: StateMachineStateConfigModel;

    isEditingName = false;

    rating = "";

    get myRating() {
        return this.rating;
    }

    set myRating(newRating: string) {
        this.rating = newRating;
    }


    updateStateName(newName: string) {
        this.state.name = newName;
    }

    addTrigger() {
        this.state.triggers.push(new TriggerConfigModelInstance(this.getUniqueTriggerName()));
    }

    deleteTrigger(triggerIdx: number) {
        if (triggerIdx >= 0 && triggerIdx < this.state.triggers.length) {
            this.state.triggers.splice(triggerIdx, 1);
        }
    }

    addEntryAction() {
        this.state.entryActions.push(new TriggerActionModelInstance());
    }

    deleteEntryAction(actionIdx: number) {
        if (actionIdx >= 0 && actionIdx < this.state.entryActions.length) {
            this.state.entryActions.splice(actionIdx, 1);
        }
    }

    addExitAction() {
        this.state.exitActions.push(new TriggerActionModelInstance());
    }

    deleteExitAction(actionIdx: number) {
        if (actionIdx >= 0 && actionIdx < this.state.exitActions.length) {
            this.state.exitActions.splice(actionIdx, 1);
        }
    }

    private getUniqueTriggerName(): string {
        let idx = 0;

        let name: string;
        let isUnique: boolean;
        do {
            idx++;
            name = "Trigger " + idx;
            isUnique = true;
            for (const trigger of this.state.triggers) {
                if (trigger.name === name) {
                    isUnique = false;
                    break;
                }
            }
        } while (!isUnique);

        return name;
    }
}
