import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { MqttTopic, MqttTopicVariable, VariableKind, VariableType } from '../../types';
import TextInput from '../state-machine-config/text-input.vue';
import MqttTopicVariableConfig from './mqtt-topic-variable-config.vue';

@Component({
    components: {
        TextInput,
        MqttTopicVariableConfig
    }
})
export default class MqttTopicComponent extends Vue {

    selectedTopicVariable: MqttTopicVariable | null = null;
    isEditingTopicVariable: boolean = false;

    @Prop() topic!: MqttTopic;

    @Emit()
    deleted() {
    }

    updateMqttTopicName(name: string) {
        this.topic.name = name;
    }

    addTopicVariable() {
        this.topic.variables.push(new DummyMqttTopicVariable());
    }

    deleteTopicVariable(topicVarIdx: number) {
        if (topicVarIdx >= 0 && topicVarIdx < this.topic.variables.length) {
            this.topic.variables.splice(topicVarIdx, 1);
        }
    }

    editTopicVariable(topicVariable: MqttTopicVariable) {
        this.selectedTopicVariable = topicVariable;
        this.isEditingTopicVariable = true;
    }

    isSelectedTopicVariable(topicVariable: MqttTopicVariable): boolean {
        return topicVariable === this.selectedTopicVariable;
    }

    selectTopicVariable(topicVariable: MqttTopicVariable) {
        this.selectedTopicVariable = topicVariable;
    }
}

class DummyMqttTopicVariable implements MqttTopicVariable {
    name: string = "";
    jsonPath: string = "";
    type: string = VariableType.Single;
    kind: string = VariableKind.Energy;
    values = [];
    doStoreValue: boolean = false;
}
