import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { TriggerConditionConfigModel, StateMachineVariableConfig, VariableValueModel, TriggerConditionKind } from '../../types';
import GuardianMessages from '../guardian-messages/guardian-messages.vue';

@Component({
    components: {
        GuardianMessages
    }
})
export default class TriggerConditionConfigComponent extends Vue {
    @Prop() condition!: TriggerConditionConfigModel;
    @Prop() variables!: StateMachineVariableConfig[];

    selectedVariable: StateMachineVariableConfig | null = null;
    selectedVariableValues: VariableValueModel[] = [];

    @Watch("condition.operandLeft")
    onVariableChanged(newVariable: string) {
        this.selectedVariable = this.variables.filter(variable => variable.name === newVariable)[0];
        if (this.selectedVariable) {
            this.selectedVariableValues = this.selectedVariable.values;
        } else {
            this.selectedVariableValues = [];
        }
    }

    @Watch("selectedVariable.variableName")
    @Watch("selectedVariable.values")
    onSelectedVariableChanged() {
        if (this.selectedVariable) {
            this.selectedVariableValues = this.selectedVariable.values;
        } else {
            this.selectedVariableValues = [];
        }
    }

    mounted() {
        this.handleUpdate();
    }

    updated() {
        this.handleUpdate();
    }

    handleUpdate() {
        switch (this.condition.kind) {
            case TriggerConditionKind.VariableToValue:
            case TriggerConditionKind.VariableToVariable:
            case TriggerConditionKind.VariableAgeToValue:
                this.onVariableChanged(this.condition.operandLeft);
                break;
        }
    }

    operatorTexts = {
        "Less": "<",
        "LessEqual": "<=",
        "Greater": ">",
        "GreaterEqual": ">=",
        "Equal": "=",
        "NotEqual": "!="
    };
}
