import { VariableValueModel } from "../../types";

export class VariableValueModelInstance implements VariableValueModel {
    value: string;
    title: string;

    constructor(name: string) {
        this.value = name;
        this.title = name;
    }
}
