import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({})
export default class TextInputComponent extends Vue {

    @Prop() value!: string;

    @Emit()
    input(text: string) {
    }

    isEditing: boolean = false;
    text: string = "";

    startEdit() {
        this.text = this.value;
        this.isEditing = true;
        this.$nextTick().then(() => {
            const inputElement = this.$refs.input;
            if (inputElement instanceof HTMLInputElement) {
                inputElement.select();
                inputElement.focus();
            }
        });
    }

    confirmEdit() {
        this.input(this.text);
        this.isEditing = false;
    }

    cancelEdit() {
        this.text = this.value;
        this.isEditing = false;
    }
}
