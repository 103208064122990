import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { TriggerActionConfigModel, StateMachineStateConfigModel, StateMachineVariableConfig, VariableValueModel } from '../../types';
import GuardianMessages from '../guardian-messages/guardian-messages.vue';

@Component({
    components: {
        GuardianMessages
    }
})
export default class TriggerActionConfigComponent extends Vue {
    @Prop() action!: TriggerActionConfigModel;
    @Prop() states!: StateMachineStateConfigModel[];
    @Prop() variables!: StateMachineVariableConfig[];

    targetState: string = "";

    variable: string = "";
    selectedVariable: StateMachineVariableConfig | null = null;
    selectedVariableValues: VariableValueModel[] = [];

    value: string = "";

    mailSubject: string = "";
    mailBody: string = "";

    script: string = "";

    @Watch("targetState")
    onTargetStateChanged(newState: string) {
        this.action.values = { "state": newState };
    }

    @Watch("variable")
    onVariableChanged(newVariable: string) {
        this.action.values = { "variable": newVariable, "value": this.value };
        this.selectedVariable = this.variables.filter(variable => variable.name === newVariable)[0];
        if (this.selectedVariable) {
            this.selectedVariableValues = this.selectedVariable.values;
        } else {
            this.selectedVariableValues = [];
        }
    }

    @Watch("selectedVariable.values")
    onSelectedVariableChanged() {
        if (this.selectedVariable) {
            this.selectedVariableValues = this.selectedVariable.values;
        } else {
            this.selectedVariableValues = [];
        }
    }

    @Watch("value")
    onValueChanged(newValue: string) {
        this.action.values = { "variable": this.variable, "value": newValue };
    }

    @Watch("mailSubject")
    onMailSubjectChanged(newMailSubject: string) {
        this.action.values = { "subject": newMailSubject, "body": this.mailBody };
    }

    @Watch("mailBody")
    onMailBodyChanged(newMailBody: string) {
        this.action.values = { "subject": this.mailSubject, "body": newMailBody };
    }

    @Watch("script")
    onScriptChanged(newScript: string) {
        this.action.values = { "script": newScript };
    }

    mounted() {
        this.handleUpdate();
    }

    updated() {
        this.handleUpdate();
        if (this.action.kind === "SetStateVariable") {
            this.onVariableChanged(this.variable);
        }
    }

    handleUpdate() {
        if (this.action.kind === "GoToState") {
            this.targetState = this.action.values["state"];
        } else if (this.action.kind === "SetStateVariable") {
            this.variable = this.action.values["variable"];
            this.value = this.action.values["value"];
        } else if (this.action.kind === "SendMail") {
            this.mailSubject = this.action.values["subject"];
            this.mailBody = this.action.values["body"];
        } else if (this.action.kind === "Script") {
            this.script = this.action.values["script"];
        }
    }
}
