import { Vue, Component, Prop, Inject, Emit } from 'vue-property-decorator';
import { GuardianConfigModel, ToastHandler } from '../../types';
import Api from '../../api';

@Component
export default class GuardianGroupConfigComponent extends Vue {

    @Inject() readonly api!: Api;
    @Inject() readonly toastHandler!: ToastHandler;
    @Prop() group!: string;

    guardians: GuardianConfigModel[] = [];
    selectedGuardian: GuardianConfigModel | null = null;

    @Emit()
    deleted() {
    }

    mounted() {
        this.reload();
    }

    update(guardian: GuardianConfigModel) {
        this.api
            .call(`api/Guardian/PutConfig/${guardian.id}`, { method: "put", body: JSON.stringify(guardian), headers: { "Content-Type": "application/json" } })
            .then(_ => this.toastHandler.show("Gespeichert."));
    }

    reload() {
        this.api.fetch<GuardianConfigModel[]>("api/Guardian/GetConfigs", { method: "POST", body: JSON.stringify(this.group ?? ""), headers: { "Content-Type": "application/json" }})
            .then(data => this.guardians = data);
    }

    selectGuardian(guardian: GuardianConfigModel) {
        this.selectedGuardian = guardian;
    }

    isSelectedGuardian(guardian: GuardianConfigModel): boolean {
        return this.selectedGuardian === guardian;
    }
}
