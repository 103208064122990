import { Vue, Component, Inject, Watch } from 'vue-property-decorator';
import { CommunicatorStateModel, GuardianGroupModel, GuardianModel, StateMachineStatus, ToastHandler, VariableModel, VariableValueModel } from '../../types';
import Api from '../../api';
import CoordinatorConfig from '../coordinator-config/coordinator-config.vue';
import GuardianGroup from './guardian-group.vue';
import ModelStore from '../../model-store';

@Component({
    components: {
        CoordinatorConfig,
        GuardianGroup
    }
})
export default class GuardianManagerComponent extends Vue {

    selectedCommunicator: CommunicatorStateModel | null = null;
    selectedStateMachine: StateMachineStatus | null = null;
    selectedGuardian: GuardianModel | null = null;

    @Inject() readonly api!: Api;
    @Inject() readonly model!: ModelStore;
    @Inject() readonly toastHandler!: ToastHandler;

    get guardianGroups(): GuardianGroupModel[] {
        const groups: GuardianGroupModel[] = [];

        for (const update of this.model.guardians) {
            let group = groups.find(g => g.name === update.group);
            if (!group) {
                group = { name: update.group, guardians: [] };
                groups.push(group);
            }

            group.guardians.push(update);
        }

        for (const group of groups) {
            group.guardians.sort((a, b) => a.name.localeCompare(b.name));
        }

        groups.sort((a, b) => a.name.localeCompare(b.name));

        return groups;
    }

    addGuardian(communicatorName: string): void {
        this.api.call(`api/Guardian/Add/${communicatorName}`);
    }

    deleteGuardian(guardian: GuardianModel): void {
        this.api.call(`api/Guardian/Delete/${guardian.id}`);
    }

    selectGuardian(guardian: GuardianModel): void {
        if (this.selectedGuardian === guardian) {
            this.selectedGuardian = null;
        } else {
            this.selectedGuardian = guardian;
        }
    }

    changeGuardianVariable(guardian: GuardianModel, variable: VariableModel, variableValue: VariableValueModel) {
        this.api.call(`api/Guardian/SetVariableValue/${guardian.id}/${variable.name}/${variableValue.value}`);
    }

    changeGuardianVariableValue(guardian: GuardianModel, variable: VariableModel, valueText: string) {
        this.api.call(`api/Guardian/SetVariableValue/${guardian.id}/${variable.name}/${valueText}`);
    }

    startDiscovery(communicatorName: string): void {
        this.api.fetch<number>(`api/GuardianCoordinator/StartDiscovery/${communicatorName}`)
            .then(data => this.toastHandler.show(`Suche nach Sensoren für Communicator "${communicatorName}" wurde gestartet. Abschluss in ${data} Sekunden.`));
    }

    isSelectedCommunicator(communicator: CommunicatorStateModel): boolean {
        return this.selectedCommunicator !== null && communicator.name === this.selectedCommunicator.name;
    }

    selectCommunicator(communicator: CommunicatorStateModel) {
        this.selectedCommunicator = this.isSelectedCommunicator(communicator) ? null : communicator;
    }
}
