import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import { StateMachineConfigModel, StateMachineStateConfigModel, TriggerConfigModel, TriggerActionConfigModel, MessageModel, StateMachineVariableConfig, VariableValueModel, StateMachineVariableType, StateMachineValueType } from '../../types';
import Api from '../../api';
import TextInput from './text-input.vue';
import GuardianMessages from '../guardian-messages/guardian-messages.vue';
import StateMachineVariableComponent from './state-machine-variable.vue';
import StateConfig from './state-config.vue';
import ModelStore from '../../model-store';
import { VariableValueModelInstance } from './variable-value-model-instance';

class StateMachineStateConfigModelInstance implements StateMachineStateConfigModel {
    name: string;
    rating = "Normal";
    triggers: TriggerConfigModel[] = [];
    entryActions: TriggerActionConfigModel[] = [];
    exitActions: TriggerActionConfigModel[] = [];
    messages: MessageModel[] = [];

    constructor(name: string) {
        this.name = name;
    }
}

class StateMachineVariableInstance implements StateMachineVariableConfig {
    name = "";
    type = StateMachineVariableType.Constant;
    valueType = StateMachineValueType.Number;
    guardianId = -1;
    variableName = "";
    constant = "";
    stateMachineId = -1;
    values: VariableValueModel[] = [];

    constructor(name: string) {
        this.name = name;
    }
}

@Component({
    components: {
        TextInput,
        GuardianMessages,
        StateMachineVariableComponent,
        StateConfig
    }
})
export default class StateMachineConfigComponent extends Vue {
    @Prop() stateMachineId!: number;

    @Inject() readonly api!: Api;
    @Inject() readonly model!: ModelStore;

    stateMachine: StateMachineConfigModel = {
        id: 0,
        name: '',
        isRunning: true,
        states: [],
        variables: [],
        messages: []
    };

    mounted() {
        this.reload();
    }

    update() {
        this.api.fetch<StateMachineConfigModel>(`api/StateMachine/Update/${this.stateMachineId}`, { method: "put", body: JSON.stringify(this.stateMachine), headers: { "content-type": "application/json" } })
            .then(data => this.assignVariableValues(data))
            .then(data => this.stateMachine = data);
    }

    reload() {
        this.api.fetch<StateMachineConfigModel>(`api/StateMachine/Get/${this.stateMachineId}`)
            .then(data => this.assignVariableValues(data))
            .then(data => this.stateMachine = data);
    }

    deleteStateMachine() {
        this.api.call(`api/StateMachine/Delete/${this.stateMachineId}`)
            .then(() => this.$router.push("/"));
    }

    updateStateMachineName(newName: string) {
        this.stateMachine.name = newName;
    }

    addState() {
        this.stateMachine.states.push(new StateMachineStateConfigModelInstance(this.getUniqueStateName()));
    }

    deleteState(index: number) {
        if (index >= 0 && index < this.stateMachine.states.length) {
            this.stateMachine.states.splice(index, 1);
        }
    }

    addVariable() {
        this.stateMachine.variables.push(new StateMachineVariableInstance(this.getUniqueVariableName()));
    }

    deleteVariable(index: number) {
        if (index >= 0 && index < this.stateMachine.variables.length) {
            this.stateMachine.variables.splice(index, 1);
        }
    }

    private assignVariableValues(stateMachineConfig: StateMachineConfigModel): StateMachineConfigModel {
        for (const variable of stateMachineConfig.variables) {
            if (variable.type === StateMachineVariableType.GuardianVariable) {
                const guardian = this.model.guardians.filter(g => g.id === variable.guardianId)[0];
                if (guardian) {
                    const selectedVariable = guardian.variables.filter(v => v.name === variable.variableName)[0];
                    if (selectedVariable) {
                        variable.values = selectedVariable.values;
                    }
                }
            } else if (variable.type === StateMachineVariableType.StateMachineState) {
                const stateMachine = this.model.stateMachines.filter(g => g.id === variable.stateMachineId)[0];
                if (stateMachine) {
                    variable.values = stateMachine.stateNames.map(sn => new VariableValueModelInstance(sn));
                }
            }
        }

        return stateMachineConfig;
    }

    private getUniqueStateName(): string {
        let idx = 0;

        let name: string;
        let isUnique: boolean;
        do {
            idx++;
            name = "Zustand " + idx;
            isUnique = true;
            for (const state of this.stateMachine.states) {
                if (state.name === name) {
                    isUnique = false;
                    break;
                }
            }
        } while (!isUnique);

        return name;
    }

    private getUniqueVariableName(): string {
        let idx = 0;

        let name: string;
        let isUnique: boolean;
        do {
            idx++;
            name = "Variable " + idx;
            isUnique = true;
            for (const mapping of this.stateMachine.variables) {
                if (mapping.name === name) {
                    isUnique = false;
                    break;
                }
            }
        } while (!isUnique);

        return name;
    }
}

