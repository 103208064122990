import { Vue, Component, Prop } from 'vue-property-decorator';
import { VariableAgeState, UiVariableModel } from '../../types';

@Component
export default class AgeStateComponent extends Vue {

    @Prop() variable!: UiVariableModel;

    get lastUpdateText(): string {
        if (this.variable.lastUpdate) {
            const lastUpdate = new Date(this.variable.lastUpdate);
            const now: Date = new Date();
            let updateSeconds = Math.ceil((now.getTime() - lastUpdate.getTime()) / 1000);
            let updateMinutes = 0;
            let updateHours = 0;
            if (updateSeconds >= 60) {
                updateMinutes = Math.floor(updateSeconds / 60);
                updateSeconds = updateSeconds - (updateMinutes * 60);
            }
            if (updateMinutes >= 60) {
                updateHours = Math.floor(updateMinutes / 60);
                updateMinutes = updateMinutes - (updateHours * 60);
            }

            return `Letzte Aktualisierung: ${lastUpdate.toLocaleDateString()} ${lastUpdate.toLocaleTimeString()}\nAlter: ${this.pad(updateHours)}:${this.pad(updateMinutes)}:${this.pad(updateSeconds)}`;
        } else {
            return "Noch keine Aktualisierung";
        }
    }

    get toolTip(): string {
        switch (this.variable.ageState) {
            case VariableAgeState.UpToDate:
                return `gültig\n${this.lastUpdateText}`;
            case VariableAgeState.Stale:
                return `veraltet\n${this.lastUpdateText}`;
            case VariableAgeState.Missing:
                return "fehlt";
            case VariableAgeState.Unknown:
                return "unbekannt";
            default:
                return "?";
        }
    }

    get state(): string {
        return this.variable.ageState;
    }

    private pad(value: number): string {
        const s = "00" + value;
        return s.substring(s.length - 2);
    }
}
