import { Vue, Component, Prop, Watch, Emit, Inject } from 'vue-property-decorator';
import { StateMachineStatus, TriggerClick } from '../../types';
import Api from '../../api';
import GuardianMessages from '../guardian-messages/guardian-messages.vue';

@Component({
    components: {
        GuardianMessages
    }
})
export default class StateMachineStatusComponent extends Vue {

    @Prop() stateMachine!: StateMachineStatus;
    @Prop() isSelected!: boolean;

    @Inject() readonly api!: Api;

    isShowingMessages: boolean = false;

    @Emit()
    selected(stateMachine: StateMachineStatus) {
    }

    @Watch("stateMachine.isEnabled")
    onIsEnabledChanged(newIsEnabled: boolean) {
        this.api.call(`api/StateMachine/Enable/${this.stateMachine.id}/${newIsEnabled}`);
    }

    handleClick(state: StateMachineStatus, click: TriggerClick) {
        click.isClicked = true;
        this.api.call(`api/StateMachine/Click/${this.stateMachine.id}/${state.currentState}/${click.name}`);
    }

    getHumanReadableTimeSpan(time: number): string {

        const days = Math.floor(time / (60 * 60 * 24));
        time -= days * (60 * 60 * 24);

        const hours = Math.floor(time / (60 * 60));
        time -= hours * (60 * 60);

        const mins = Math.floor(time / 60);
        time -= mins * 60;

        const seconds = Math.floor(time);

        if (days > 0) {
            return `${days} Tage ${hours} h ${mins} m ${seconds} s`;
        } else if (hours > 0) {
            return `${hours} h ${mins} m ${seconds} s`;
        } else if (mins > 0) {
            return `${mins} m ${seconds} s`;
        } else {
            return `${seconds} s`;
        }
    }

    getAllMessages(): string {
        return this.stateMachine.messages.map(m => m.id + " " + m.text).join(", ");
    }
}
